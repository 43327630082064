<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


import UserInscription from "./UserInscription";
import UserWallet from "./UserWallet";
import PublicationTrajet from "./PublicationTrajet";
import ReservationTrajet from "./ReservationTrajet";
import Multiselect from "vue-multiselect";


export default {
  page: {
    title: "Expérience Commercial",
    meta: [{ name: "description", content: appConfig.description }],
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    UserInscription,
    UserWallet,
    PublicationTrajet,
    ReservationTrajet


  },
  data() {
    return {
      title: "Expérience",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      showDetail: false,
      items: [
       
        {
          text: "Agent commercial",
          active: true,
        },
      ],
      moyensTransport: ["MOTO", "VOITURE", "TAXI", "BUS"],
      typesDistance: ["COURT", "MOYEN", "LONG"],
      experience: ["R-Débutant", "R-Qualifié", "R-Ambassadeur"],
      codePromoForm: {
        titre: "",
        description: "",
        pathImage: "",
      },
      codePromoFormModif: {
        titre: "",
        description: "",
        pathImage: "",
      },
      codePromoRowInfo: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      codePromo: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
   
      abonnements: [],
      labels: [
        {
          nom: { title: "Nom et Prénom" },
          trajet: { title: "Trajets" },
          options: { title: "Options" },
          montant: { title: "Montant" },
          date: { title: "Date" },
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showactive: false,
      erreur: false,
      yearSelected: { name: "2022", value: 2022 },
      yearvalue: 2022,
      defaultMonth: null,
      defaultChoice: { id: null, value: "" },
      years: [
        2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,
        2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044,
        2045, 2046, 2047, 2048, 2049, 2050,
      ],
    
      nombre: 0,
      totalRecette: 0,
      today: new Date(),
      month: [
        { id: "01", value: "Janvier" },
        { id: "02", value: "Février" },
        { id: "03", value: "Mars" },
        { id: "04", value: "Avril" },
        { id: "05", value: "Mai" },
        { id: "06", value: "Juin" },
        { id: "07", value: "Juillet" },
        { id: "08", value: "Août" },
        { id: "09", value: "Septembre" },
        { id: "10", value: "Octobre" },
        { id: "11", value: "Décembre" },
        { id: "12", value: "Décembre" },
      ],
      currentTime: "",
      idFacture: "",
      numUser: "",
      numberUserCode: "",
      emailUser: "",
      departTrajet: "",
      pointRencontreDepart: "",
      arriveeTrajet: "",
      pointArriveTrajet: "",
      description: "",
      quantite: 0,
      prixunitaireTrajet: 0,
      totalTrajet: 0,
      descriptionAssurance: "",
      quantiteAssurance: 0,
      PrixUnitaireAssurance: 0,
      totalAssurance: 0,
      totalHT: 0,
      tva: 0.18,
      totalTTC: 0,
      nameUser: "",
      userAdresse: "",
      depart: "",
      arrive: "",
      cout: 0,
      dateReserv: "",
      couTAssurance: 0,

      ttc: 0,
      niveauUser:JSON.parse(localStorage.getItem("user")).niveau,

      abonnementPaye:[],
      codeparrainage:"",

    };
  },
  validations: {},

  mounted() {
    this.codeparrainage = this.$route.params.codeparrainage;
   this.yearvalue=  this.today.getFullYear();
 /*    console.log("le code de parrainage est :", this.codeparrainage);
    this.yearvalueU = this.today.getFullYear();
    this.yearvalue = this.today.getFullYear();
    this.defaultMonth = this.month[this.today.getMonth()];

    console.log(this.month[this.today.getMonth()]); */
/*     this.loadData(); */

 
  },
  methods: {
  


/*     async loadData() {
      this.totalRecette = 0;
      const gains = await apiRequest(
        "GET",
        "assurance/start",
        undefined,
        false
      );

      if (gains && gains.data) {
        this.totalRows = gains.data.totalAssurance;
        console.log(this.totalRows);
        let dataRecette = gains.data.annees;
        dataRecette.forEach((element) => {
          if (this.yearvalue == element.annee) {
            this.totalRecette += element.total;
          }
        });
        //this.totalRecette = gains.data.totalRecette;
      }
    }, */



    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },



    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },

  computed: {
    filterAssuranceByMonth: function () {
      return this.abonnementPaye.filter(
        (abonnement) =>
          !abonnement.date_assurance
            .split(" ")[0]
            .split("/")[1]
            .indexOf(this.defaultChoice.id)
      );
    },
  },

  watch: {
    codeparrainage: {
      handler(val) {
        console.log("l'année sélectionnée est :", val);
        this.codeparrainage;
      },
    },

    actualYear: {
      handler(val) {
        console.log("l'année sélectionnée est :", val);
        this.actualYear;
      },
    },

    defaultMonth: {
      handler(val) {
        console.log(Number(val.id));

        if (val.id != "" && val.id) {
          this.defaultChoice.id = val.id;
          this.defaultChoice.value = val.value;
        }
      },
    },

    /*  
     let  date =Number(item.date_assurance.split(" ")[0].split('/')[1])
         console.log(date);
            return date===(Number(val.id)); 
   
        }


      }
      */
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="p-1">
          <div class="row mb-4 d-flex justify-content-center">
            <div class="mx-auto d-flex justify-content-between">
              <div class="">
                <multiselect
                  style="text-align: center"
                  v-model="yearvalue"
                  :options="years"
                  :close-on-select="true"
                  :show-labels="false"
                  :allow-empty="false"
                ></multiselect>
              </div>
              &nbsp;&nbsp;
             <!--  <div class="mt-2">Total : {{ totalRecette }}</div> -->
            </div>
          </div>
          <!-- Bar Chart -->
          <div class="row">
            <div class="col-md-6">
              <b-card>
            <UserInscription :height="300" :codeparrainage="codeparrainage" :actualYear ="yearvalue" />
          </b-card>
            </div>
            
            <div class="col-md-6">
              <b-card>
            <UserWallet :height="300" :codeparrainage="codeparrainage" :actualYear ="yearvalue"/>
          </b-card>
            </div>


            <div class="col-md-6">
              <b-card>
            <PublicationTrajet :height="300" :codeparrainage="codeparrainage" :actualYear ="yearvalue" />
          </b-card>
            </div>
            <div class="col-md-6">
              <b-card>
            <ReservationTrajet :height="300" :codeparrainage="codeparrainage"  :actualYear ="yearvalue" />
          </b-card>
            </div>
          </div>
         
        </div>
      </div>
    </div>



    <b-modal
      id="modal-backdrop"
      centered
      v-model="erreur"
      title="Veuillez remplir toutes les options."
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="info" class="ml-3 mr-4" @click="erreur = false"
          >Ok</b-button
        >
      </div>
    </b-modal>

  
  </Layout>
</template>
<style scoped>
.imageCard {
  display: flex;
  align-items: center;
  flex-direction: column;
}

thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

#chart {
  position: relative;
  margin-top: 40px;
  width: 500px;
  height: 200px;
}
@-webkit-keyframes bargrowth {
  0% {
    max-height: 0%;
  }
  100% {
    max-height: 100%;
  }
}
@keyframes bargrowth {
  0% {
    max-height: 0%;
  }
  100% {
    max-height: 100%;
  }
}
.bar {
  -webkit-animation: bargrowth 1000ms ease;
  animation: bargrowth 1000ms ease;
  position: absolute;
  bottom: 0;
  display: inline-block;
  background: #89b800;
  box-shadow: 1px -1px #79a300, 2px -2px #79a300, 3px -3px #79a300,
    4px -4px #79a300, 5px -5px #79a300, 6px -6px #79a300, 7px -7px #79a300,
    8px -8px #79a300, 9px -9px #79a300, 10px -10px #79a300;
}

.wrapper {
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  width: 500px;
  font-family: Helvetica;
  font-size: 12px;
}
.wrapper > h1,
.wrapper > p {
  margin: 0;
}

.button__custom {
  color: var(--gray-0);
  background-color: #37b24d;
  height: 2.3rem;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  margin-bottom: 15px;
}

.exportFlex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 400px;
}

.multiselect__tags {
  border: 3px solid #37b24d !important;
  color: #37b24d !important;
}
.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}



@media screen {
  #print {
    display: none;
   }
}

@media print {
 body * {
  visibility:hidden;
  }
  #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    left:0;
    top:0;
  }
}



.np-btn {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #da1010;
  width: 110px;
  background: #da1010;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
}
</style>
